/* eslint-disable camelcase */
import { pushToDataLayer } from '../pushToDataLayer';

/**
 * DataLayer push on file download click event.
 * This function is used also for promo box click event (promoBoxListeners.js).
 * @param {Event} event - The click event object.
 */
export const clickOnFileDownload = (event) => {
  let linkText;
  let targetHref;
  const $currentTarget = $(event.currentTarget);

  try {
    targetHref = new URL($currentTarget.attr('href'));
  } catch (error) {
    return;
  }

  const fileExtension = targetHref.pathname.split('.').pop();

  if ($currentTarget.hasClass('ga-pushbox-link')) {
    linkText = $currentTarget.find('.btn span').text().trim();
  } else if ($currentTarget.hasClass('ga-banner-cta-promotion')) {
    linkText = $currentTarget.find('.cta-btn-text').text().trim();
  } else if (($currentTarget.closest('.ProductContent').length > 0)) {
    linkText = $currentTarget.text().trim();
  } else if ($currentTarget.hasClass('footer-text-angle-link')) {
    linkText = $currentTarget.text().trim();
  } else if ($currentTarget.hasClass('ga-campaign-promotion')) {
    linkText = $currentTarget.text().trim();
  } else {
    linkText = $currentTarget.find('span').text().trim();
  }

  const allowedFileTypes = ['pdf', 'doc', 'docx', 'xml', 'csv', 'xls', 'xlsx', 'txt', 'ppt', 'pptx', 'zip', 'rar'];
  /*
  - Matches the last "/" followed by the filename (excluding "/").
  - Captures the filename before the extension.
  - Matches a dot "." before the allowed file extensions.
  - Ensures the extension is one of the listed ones.
  - Ends at the string boundary "$".
  - Case-insensitive "i".
  */
  const fileNameRegex = new RegExp('\\/([^/]+)\\.(pdf|doc|docx|xml|csv|xls|xlsx|txt|ppt|pptx|zip|rar)$', 'i');
  const locationHostname = window.location.hostname.replace(/^www\./, '');

  if (allowedFileTypes.includes(fileExtension)) {
    pushToDataLayer({
      event: 'ga4_file_download',
      ga4_data: {
        file_extension: fileExtension.toUpperCase(),
        file_name: targetHref.href.match(fileNameRegex)[1],
        link_text: linkText,
        link_url: targetHref.href,
        file_type: 'General download',
        link_domain: locationHostname,
      }
    });
  }
}

export const addFileDownloadListeners = () => {
  // File download event listener for:
  // item box (item-box.hbs),
  // call to action (call-to-action-item.hbs),
  // product-intro (product-intro-content.hbs),
  // footer (footer-column-item.hbs),
  // campaign (campaign-image-content.hbs).
  $('.ga4-item-box-link, .ga-banner-cta-promotion, ProductContent a, .footer-text-angle-link, .ga-campaign-promotion').on('click', event => clickOnFileDownload(event));
};
